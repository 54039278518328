import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from 'constants/colors';

const ArrowGeo: React.FC<IIcon> = ({ color = colors.blackRussian, ...props }) => (
  <Icon width="15" height="18" viewBox="0 0 15 18" fill="none" {...props}>
    <path
      d="M1.43546 9.6137L7.15769 10.0537L7.744 16.0393L14.0194 2.48055L1.43546 9.6137Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </Icon>
);

export default ArrowGeo;
