import React from 'react';
import Icon, { IIcon } from './Icon';

const PlusIcon: React.FC<IIcon> = props => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path d="M5 11H17.5" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" />
    <path d="M10 17L12.5 5" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" />
  </Icon>
);

export default PlusIcon;
