import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from 'constants/colors';

const MinusIcon: React.FC<IIcon> = ({ color = colors.blackRussian, ...props }) => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path d="M5 11H17" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
  </Icon>
);

export default MinusIcon;
