import { GeoObjectState } from 'utils/hooks/useMap';

export interface IPlacemarkOptions {
  id: number;
  full_logo: string;
  short_logo: string;
}

type IPlacemarkData = number[][];

const getPlacemarkOptions = (
  state: GeoObjectState,
  options: IPlacemarkOptions,
  [offset, sizeFull, sizeShort]: IPlacemarkData
) => {
  const defaultOptions = {
    iconLayout: 'default#image',
    iconImageOffset: offset,
    zIndex: 1,
    id: options.id,
  };

  switch (state) {
    case GeoObjectState.active:
      return {
        ...defaultOptions,
        iconImageSize: sizeFull,
        iconImageHref: options.full_logo,
      };
    case GeoObjectState.normal:
      return {
        ...defaultOptions,
        iconImageSize: sizeShort,
        iconImageHref: options.short_logo,
      };
  }
};

export default getPlacemarkOptions;
