import { ICoordinate } from 'Modules/Places/types';
import pin from 'Containers/SelectAddress/MapBuilder/img/pin.svg';

const createCurrentAddressPin = (map_state: any, coords: ICoordinate) => {
  map_state.currentAddressCollection?.removeAll();
  if (map_state.ymaps) {
    const currentAddressPin = new map_state.ymaps.Placemark(
      [coords.latitude, coords.longitude],
      {},
      {
        iconLayout: 'default#image',
        iconImageHref: pin,
        iconImageSize: [38, 64],
        iconImageOffset: [-19, -64],
      }
    );
    map_state.currentAddressCollection.add(currentAddressPin);
  }
  map_state.ymapsInstance?.geoObjects.add(map_state.currentAddressCollection);
};

export default createCurrentAddressPin;
