import { EnumBugsnagErrors } from 'constants/enums';
import { sendCustomBugsnagReport } from 'utils/errors';
import SearchAddressActions from 'Modules/SearchAddress/actions';
import NotificationActions from 'Modules/Notifications/actions';
import { MAP_CLICK_ERROR_MESSAGE, ZoomLevels } from 'utils/hooks/useMap';

export const LOCAL_STORAGE_ADDRESS_FROM_MAP_KEY = 'LOCAL_STORAGE_ADDRESS_FROM_MAP_KEY';

export const setMapZoomListener = (map_state: any) => {
  map_state.ymapsInstance.events.add('boundschange', (e: any) => {
    const zoom = e.get('newZoom');
    if (zoom >= ZoomLevels.MIN_CLOSE) {
      map_state.ymapsInstance.geoObjects.remove(map_state.zonesCollection);
    } else if (map_state.currentShopId === -1) {
      if (!map_state.zonesCollection?.getParent()) {
        map_state.ymapsInstance.geoObjects.add(map_state.zonesCollection);
      }
    }
  });
};

export const setMapClickListener = (map_state: any, city_id: number, dispatch: any, callback?: () => void) => {
  map_state.ymapsInstance.events.add('click', async (e: any) => {
    const currentCoords = e.get('coords');
    try {
      const place = await dispatch(SearchAddressActions.updateCurrentAddressFromCoords(currentCoords));
      const localStorageData = JSON.stringify(place);
      sessionStorage.setItem(LOCAL_STORAGE_ADDRESS_FROM_MAP_KEY, localStorageData);
    } catch (e) {
      sendCustomBugsnagReport(EnumBugsnagErrors.GEOCODE_MAP_ERROR, {
        e,
        city_id,
        currentCoords,
      });
      dispatch(NotificationActions.setNotificationAction(MAP_CLICK_ERROR_MESSAGE));
    } finally {
      if (callback) {
        callback();
      }
    }
  });
};
