export enum statesSA {
  searchAddress = 'search_address',
  selectShop = 'select_shop',
  coverageZone = 'coverageZone',
}

export const GO_TO_SA_BUTTON_DESKTOP_TEST_ID = 'GO_TO_SA_BUTTON_DESKTOP_TEST_ID';
export const GO_TO_SA_BUTTON_MOBILE_TEST_ID = 'GO_TO_SA_BUTTON_MOBILE_TEST_ID';

export const SELECT_ADDRESS_TEST_ID = 'SELECT_ADDRESS_TEST_ID';
