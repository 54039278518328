import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'constants/colors';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import { isBrowser } from 'utils/helpers';
import getPlacemarkOptions from 'utils/ya_map/getPlacemarkOptions';
import { loadMapScript } from 'utils/ya_map/loadMapScript';
import ymapRoundStyle from 'utils/ya_map/yMapControlsDraw';
import { statesSA } from 'Containers/SelectAddress/constants';
import http, { IShopsForPlaceResponse, IShopsWithZonesResponse } from 'Modules/Shops/http';
import { getCurrentAddress, getCurrentCity } from 'Modules/SearchAddress/selectors';
import { IDeliveryZoneWithCoordinates, IShopForPlace, IShopWithZones } from 'Modules/Shops/types';
import { IUseDataApiState } from 'utils/hooks';
import { getCurrentPosition } from 'Modules/SearchAddress/actions';
import { IPlace } from 'Modules/Places/types';
import setMapCollections from 'utils/ya_map/setMapCollections';
import { setMapClickListener, setMapZoomListener } from 'utils/ya_map/setMapListeners';
import createCurrentAddressPin from 'utils/ya_map/createCurrentAddressPin';
import updateCenter from 'utils/ya_map/updateCenter';
import { Box, Flex } from '@igooods/ui-kit';
import PlusIcon from 'Components/Icon/PlusNew';
import MinusIcon from 'Components/Icon/MinusNew';
import ArrowGeo from 'Components/Icon/ArrowGeo';
import { ThemeButton } from 'Components/ThemedButton';
import styled from 'styled-components';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { mediaSmDown } from 'theme/helpers/css';
import { COVERAGE_Y_MAP_ID, Y_MAP_ID } from 'Containers/SelectAddress/MapBuilder';
import { FAKE_CITY_ID } from 'constants/constants';

export const MAP_CLICK_ERROR_MESSAGE = 'Ошибка выбора адреса';

const PLACEMARK_OFFSET = [-30, -30];
const SIZE_FULL_PLACEMARK = [70, 30];
const SIZE_SHORT_PLACEMARK = [30, 30];

const SHOPS_BTN_TEST_ID = 'SHOPS_BTN_TEST_ID';
const DESKTOP_INDENT = 16;
const CUSTOM_BOX_SHADOW = '0px 0px 16px rgba(27, 27, 95, 0.08), 0px 20px 24px rgba(27, 27, 95, 0.04)';

const MapToggleBtn = styled(ThemeButton)`
  position: absolute;
  z-index: 1;
  top: ${DESKTOP_INDENT}px;
  left: ${DESKTOP_INDENT}px;
  box-shadow: ${CUSTOM_BOX_SHADOW};
  max-width: 250px;
  padding: 0 24px;
  min-height: 48px;
  font-size: 16px;
`;

const IconButton = styled(ThemeButton).attrs({
  use: EnumTheme.roundWhite,
  icon: true,
})`
  width: 38px;
  height: 38px;
  box-shadow: ${CUSTOM_BOX_SHADOW};
  margin-right: 1px;
`;

const MapControls = styled(Flex).attrs({ column: true })<{ $isScale?: boolean }>`
  position: absolute;
  z-index: 1;
  top: ${DESKTOP_INDENT}px;
  right: ${DESKTOP_INDENT}px;
  ${IconButton} {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
  ${addStyleIfPropTruly('$isScale', 'top: calc(50% - 67px);')}
  ${mediaSmDown`
  top: calc(50% - 67px);
  right: 10px;
`}
`;

enum MapState {
  ZonesOnly = 'zones-only',
  ZonesWithShops = 'zones-with-shops',
}

export enum GeoObjectState {
  active = 'active',
  normal = 'normal',
}

const ZONE_OPTIONS = {
  strokeWidth: 0,
  interactivityModel: 'default#transparent',
  fillColor: colors.orange,
  fillOpacity: 0.12,
};

export enum ZoomLevels {
  LG_FAR = 10,
  FAR = 12,
  NORMAL = 14,
  MIN_CLOSE = 15,
  MAX_CLOSE = 16,
}

interface IUseMapProps {
  modePage: statesSA;
  isScale?: boolean;
  shopsForPlace?: IUseDataApiState<IShopsForPlaceResponse>;
  toggleScale?: () => void;
  saveAddressChanges?: () => void;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    Ymap: any;
  }
}

let sendingData = false;

export const useMap = ({
  modePage,
  saveAddressChanges,
  shopsForPlace,
  isScale,
}: IUseMapProps): { controls: JSX.Element | null } => {
  const dispatch = useDispatch();
  const [yaMapState, setYaMapState] = useState<any>(null);
  const [mapMainDataSet, setMapMainDataSet] = useState(false);
  const [isUnmount, setIsUnmount] = useState(false);
  const [cityId, setCityId] = useState<number | null>(null);

  const city = useSelector(getCurrentCity);
  const currentAddress = useSelector(getCurrentAddress);

  const renderShopsForPlace = (ya_map_state: any, shops: IShopWithZones[], { coordinates }: IPlace) => {
    ya_map_state.shopsForPlaceCollection.removeAll();
    ya_map_state.shopsCollection.removeAll();
    ya_map_state.zonesCollection.removeAll();

    createCurrentAddressPin(ya_map_state, coordinates);

    shops.forEach((shop: IShopWithZones) => {
      const placemarkOptions = {
        id: shop.id,
        color: shop.color,
        full_logo: shop.logo.svg_o_active_logo_path,
        short_logo: shop.logo.svg_o_logo_path,
      };
      const placemark = new ya_map_state.ymaps.Placemark(
        [shop.coordinate.latitude, shop.coordinate.longitude],
        {},
        getPlacemarkOptions(GeoObjectState.active, placemarkOptions, [
          PLACEMARK_OFFSET,
          SIZE_FULL_PLACEMARK,
          SIZE_SHORT_PLACEMARK,
        ])
      );
      ya_map_state.shopsForPlaceCollection.add(placemark);
      shop.all_zones.forEach((zone: IDeliveryZoneWithCoordinates) => {
        const coordinate = zone.coordinates.map(a => [a.latitude, a.longitude]);
        const polygon = new ya_map_state.ymaps.Polygon([coordinate], {}, ZONE_OPTIONS);
        ya_map_state.shopsForPlaceCollection.add(polygon);
      });
    });
    if (ya_map_state.ymapsInstance) {
      ya_map_state.ymapsInstance.geoObjects.add(ya_map_state.shopsForPlaceCollection as any);
    }
  };

  const fetchShopsZone = useCallback(
    (id: number, callback: () => void) => {
      if (sendingData) return;
      if (id !== FAKE_CITY_ID) {
        sendingData = true;
        return http.getShopsWithZones(id).then((res: IShopsWithZonesResponse) => {
          if (!isUnmount) {
            callback();
            setYaMapState((current: any) => ({
              ...current,
              allShops: res.list,
            }));
          }
          sendingData = false;
        });
      } else {
        return Promise.reject('your soul is mine!');
      }
    },
    [isUnmount]
  );

  const selectShop = useCallback((id: number, index: number, placemark: any) => {
    setYaMapState((current: any) => ({
      ...current,
      currentShopId: id,
      currentShopIndex: index,
      currentShopPlacemarkOptions: placemark,
    }));
  }, []);

  const parseShops = useCallback(
    (ya_map_state: any) => {
      ya_map_state.shopsCollection?.removeAll();
      ya_map_state.zonesCollection?.removeAll();
      ya_map_state.allShops.forEach((shop: IShopWithZones) => {
        if (ya_map_state.mapState === MapState.ZonesWithShops) {
          const placemarkOptions = {
            id: shop.id,
            color: shop.color,
            full_logo: shop.logo.svg_o_active_logo_path,
            short_logo: shop.logo.svg_o_logo_path,
          };

          const placemark = new ya_map_state.ymaps.Placemark(
            [shop.coordinate.latitude, shop.coordinate.longitude],
            {},
            getPlacemarkOptions(GeoObjectState.normal, placemarkOptions, [
              PLACEMARK_OFFSET,
              SIZE_FULL_PLACEMARK,
              SIZE_SHORT_PLACEMARK,
            ])
          );
          ya_map_state.shopsCollection.add(placemark);
          placemark.events.add('mouseenter', (e: any) => {
            if (e.get('target').options.get('id') !== ya_map_state.currentShopId) {
              e.get('target').options.set(
                getPlacemarkOptions(GeoObjectState.active, placemarkOptions, [
                  PLACEMARK_OFFSET,
                  SIZE_FULL_PLACEMARK,
                  SIZE_SHORT_PLACEMARK,
                ])
              );
            }
          });
          placemark.events.add('mouseleave', (e: any) => {
            if (e.get('target').options.get('id') !== ya_map_state.currentShopId) {
              e.get('target').options.set(
                getPlacemarkOptions(GeoObjectState.normal, placemarkOptions, [
                  PLACEMARK_OFFSET,
                  SIZE_FULL_PLACEMARK,
                  SIZE_SHORT_PLACEMARK,
                ])
              );
            }
          });
          placemark.events.add('click', (e: any) => {
            if (modePage === statesSA.coverageZone) return false;
            ya_map_state.currentShopZoneCollection.removeAll();
            const { geometry } = e.originalEvent.target;
            const id = e.get('target').options.get('id');
            const index = ya_map_state.shopsCollection.indexOf(e.get('target'));
            if (ya_map_state.currentShopId === id) {
              ya_map_state.ymapsInstance?.geoObjects.add(ya_map_state.zonesCollection);
              e.get('target').options.set(
                getPlacemarkOptions(GeoObjectState.normal, placemarkOptions, [
                  PLACEMARK_OFFSET,
                  SIZE_FULL_PLACEMARK,
                  SIZE_SHORT_PLACEMARK,
                ])
              );
              updateCenter(ya_map_state, geometry.getCoordinates(), ZoomLevels.FAR);
              setYaMapState((current: any) => ({ ...current, currentShopId: -1 }));
              return;
            }
            ya_map_state.ymapsInstance?.geoObjects.remove(ya_map_state.zonesCollection);
            if (ya_map_state.currentShopIndex >= 0) {
              ya_map_state.shopsCollection
                .get(ya_map_state.currentShopIndex)
                .options.set(
                  getPlacemarkOptions(GeoObjectState.normal, ya_map_state.currentShopPlacemarkOptions, [
                    PLACEMARK_OFFSET,
                    SIZE_FULL_PLACEMARK,
                    SIZE_SHORT_PLACEMARK,
                  ])
                );
            }
            selectShop(id, index, placemarkOptions);
            updateCenter(ya_map_state, geometry.getCoordinates(), ZoomLevels.FAR);
            e.get('target').options.set(
              getPlacemarkOptions(GeoObjectState.active, placemarkOptions, [
                PLACEMARK_OFFSET,
                SIZE_FULL_PLACEMARK,
                SIZE_SHORT_PLACEMARK,
              ])
            );
            shop.all_zones.forEach((zone: IDeliveryZoneWithCoordinates) => {
              const coordinate = zone.coordinates.map(coords => [coords.latitude, coords.longitude]);
              const polygon = new ya_map_state.ymaps.Polygon(
                [coordinate],
                {},
                {
                  strokeWidth: 0,
                  interactivityModel: 'default#transparent',
                  fillColor: shop.color,
                  fillOpacity: 0.5,
                }
              );
              ya_map_state.currentShopZoneCollection.add(polygon);
            });
            ya_map_state.ymapsInstance?.geoObjects.add(ya_map_state.currentShopZoneCollection as any);
            ya_map_state.zonesCollection.removeAll();
          });
          shop.all_zones.forEach((zone: IDeliveryZoneWithCoordinates) => {
            const coordinate = zone.coordinates.map(a => [a.latitude, a.longitude]);
            const polygon = new ya_map_state.ymaps.Polygon([coordinate], {}, ZONE_OPTIONS);
            ya_map_state.zonesCollection.add(polygon);
          });
        }
      });

      if (ya_map_state.ymapsInstance) {
        ya_map_state.ymapsInstance.geoObjects.add(ya_map_state.shopsCollection as any);
        ya_map_state.ymapsInstance.geoObjects.add(ya_map_state.zonesCollection as any);
      }
      setYaMapState((current: any) => ({ ...current, isLoading: false }));
    },
    [modePage, selectShop]
  );

  const yMapSuccess = useCallback(
    (api: any) => {
      const instance = new api.Map(
        Y_MAP_ID,
        {
          center: [city.coordinate.latitude, city.coordinate.longitude],
          zoom: ZoomLevels.FAR,
          controls: [],
        },
        { yandexMapDisablePoiInteractivity: true, suppressMapOpenBlock: true }
      );

      setYaMapState({
        ymaps: api,
        ymapsInstance: instance,
        shopsCollection: new api.GeoObjectCollection(),
        zonesCollection: new api.GeoObjectCollection(),
        shopsForPlaceCollection: new api.GeoObjectCollection(),
        currentShopZoneCollection: new api.GeoObjectCollection(),
        currentAddressCollection: new api.GeoObjectCollection(),
        hopsForPlace: [],
        allShops: [],
        currentShopId: -1,
        currentShopIndex: -1,
        currentShopPlacemarkOptions: {},
        mapState: modePage === statesSA.coverageZone ? MapState.ZonesWithShops : MapState.ZonesOnly,
        isLoading: false,
      });
    },
    [city, modePage]
  );

  const increaseZoom = (ya_map_state: any) => {
    if (ya_map_state.ymapsInstance) {
      const currentZoom = ya_map_state.ymapsInstance.getZoom();
      ya_map_state.ymapsInstance.setZoom(currentZoom + 1);
    }
  };

  const decreaseZoom = (ya_map_state: any) => {
    if (ya_map_state.ymapsInstance) {
      const currentZoom = ya_map_state.ymapsInstance.getZoom();
      ya_map_state.ymapsInstance.setZoom(currentZoom - 1);
    }
  };

  const handleClickLocation = () => {
    getCurrentPosition(dispatch, city.id);
  };

  const handleToggleMapState = () => {
    setYaMapState((current: any) => ({
      ...current,
      mapState: current.mapState === MapState.ZonesOnly ? MapState.ZonesWithShops : MapState.ZonesOnly,
    }));
  };

  useEffect(() => {
    if (isBrowser) {
      if (!window.Ymap) window.Ymap = {};
      (window as any)['_ymap-api-onload'] = yMapSuccess;
      loadMapScript();
      setIsUnmount(false);
    }
    return () => {
      if (window.Ymap) window.Ymap = null;
      (window as any)['_ymap-api-onload'] = () => null;
      setYaMapState(null);
      setIsUnmount(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mapMainDataSet && yaMapState) setMapMainDataSet(true);
    if (!yaMapState && mapMainDataSet) setMapMainDataSet(false);
  }, [mapMainDataSet, yaMapState]);

  useEffect(() => {
    if (mapMainDataSet) {
      ymapRoundStyle(yaMapState);

      setMapCollections(yaMapState);

      yaMapState.ymapsInstance.geoObjects.add(yaMapState.currentShopZoneCollection);

      yaMapState.ymapsInstance.cursors.push('arrow');

      setMapZoomListener(yaMapState);

      setMapClickListener(yaMapState, city.id, dispatch, saveAddressChanges);

      fetchShopsZone(city.id, () => parseShops(yaMapState));

      if (!cityId) setCityId(city.id);
    }
    // eslint-disable-next-line
  }, [mapMainDataSet, fetchShopsZone]);

  useEffect(() => {
    if (cityId && city.id !== cityId) {
      setYaMapState((current: any) => ({ ...current, isLoading: true, currentShopId: -1, currentShopIndex: -1 }));
      yaMapState.currentShopZoneCollection?.removeAll();
      yaMapState.shopsCollection?.removeAll();
      yaMapState.zonesCollection?.removeAll();
      yaMapState.currentAddressCollection?.removeAll();
      yaMapState.ymapsInstance?.geoObjects.removeAll();
      setYaMapState((current: any) => ({
        ...current,
        mapState: MapState.ZonesOnly,
      }));

      fetchShopsZone(city.id, () => parseShops(yaMapState));
      updateCenter(yaMapState, [city.coordinate.latitude, city.coordinate.longitude], ZoomLevels.FAR);

      setCityId(city.id);
    }
    // eslint-disable-next-line
  }, [city.id, fetchShopsZone]);

  useEffect(() => {
    if ((mapMainDataSet && modePage === statesSA.searchAddress) || modePage === statesSA.coverageZone) {
      setYaMapState((current: any) => ({
        ...current,
        isLoading: true,
        currentShopId: -1,
        currentShopIndex: -1,
      }));
      yaMapState.currentShopZoneCollection?.removeAll();
      yaMapState.shopsCollection?.removeAll();
      yaMapState.zonesCollection?.removeAll();

      if (yaMapState.ymapsInstance) {
        yaMapState.ymapsInstance.geoObjects.removeAll();
        yaMapState.ymapsInstance.geoObjects.add(yaMapState.currentShopZoneCollection);
        yaMapState.ymapsInstance.geoObjects.add(yaMapState.currentAddressCollection);
      }

      setTimeout(() => {
        fetchShopsZone(city.id, () => parseShops(yaMapState));
      }, 128);
    }

    if (mapMainDataSet && modePage === statesSA.selectShop && shopsForPlace) {
      const { list: shops } = shopsForPlace.data;
      const shopsForPlaceWithZones: IShopWithZones[] = [];

      if (yaMapState.ymaps) {
        shops.forEach((sp: IShopForPlace) => {
          const shopWithZone = yaMapState.allShops.find((s: IShopWithZones) => s.id === sp.id);
          if (shopWithZone) shopsForPlaceWithZones.push(shopWithZone);
        });
        setTimeout(() => renderShopsForPlace(yaMapState, shopsForPlaceWithZones, currentAddress), 128);
        setTimeout(async () => {
          return updateCenter(
            yaMapState,
            [currentAddress.coordinates.latitude, currentAddress.coordinates.longitude],
            ZoomLevels.NORMAL
          );
        }, 256);
      }
    }
    // eslint-disable-next-line
  }, [modePage, fetchShopsZone]);

  useEffect(() => {
    if (mapMainDataSet) {
      const { coordinates } = currentAddress;
      if (coordinates) {
        yaMapState.shopsForPlaceCollection?.removeAll();
        yaMapState.currentShopZoneCollection?.removeAll();
        createCurrentAddressPin(yaMapState, coordinates);
        updateCenter(yaMapState, [coordinates.latitude, coordinates.longitude]);
      } else {
        yaMapState?.currentAddressCollection?.removeAll();
      }
    }
    // eslint-disable-next-line
  }, [currentAddress.name, mapMainDataSet]);

  useEffect(() => {
    if (mapMainDataSet && yaMapState?.mapState) {
      if (yaMapState.mapState === MapState.ZonesOnly) {
        yaMapState.currentShopZoneCollection?.removeAll();
        yaMapState.shopsForPlaceCollection?.removeAll();
      }
      if (!yaMapState.zonesCollection?.getParent()) {
        yaMapState.ymapsInstance?.geoObjects.add(yaMapState.zonesCollection);
      }

      fetchShopsZone(city.id, () => parseShops(yaMapState));
    }
    // eslint-disable-next-line
  }, [mapMainDataSet, yaMapState?.mapState, fetchShopsZone]);

  useEffect(() => {
    if (isScale && mapMainDataSet) {
      yaMapState.ymapsInstance?.container?.fitToViewport();
    }
    // eslint-disable-next-line
  }, [isScale, mapMainDataSet]);

  return {
    controls:
      mapMainDataSet && modePage === statesSA.searchAddress ? (
        <>
          <Box hideBefore="sm">
            <MapToggleBtn
              testId={SHOPS_BTN_TEST_ID}
              size={EnumButtonSize.small}
              use={EnumTheme.roundWhite}
              onClick={handleToggleMapState}
              isLoading={yaMapState.isLoading}
            >
              {yaMapState?.mapState === MapState.ZonesWithShops ? 'Скрыть магазины' : 'Показать магазины'}
            </MapToggleBtn>
          </Box>
          <MapControls $isScale={isScale}>
            <IconButton onClick={() => increaseZoom(yaMapState)}>
              <PlusIcon />
            </IconButton>
            <IconButton onClick={() => decreaseZoom(yaMapState)}>
              <MinusIcon />
            </IconButton>
            <IconButton onClick={handleClickLocation}>
              <ArrowGeo />
            </IconButton>
          </MapControls>
        </>
      ) : null,
  };
};

export const useCoverageZonesMap = () => {
  const yaMapState = useRef<any>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const city = useSelector(getCurrentCity);

  const parseShops = useCallback((ya_map_state: any) => {
    ya_map_state.allShops.forEach((shop: IShopWithZones) => {
      const placemarkOptions = {
        id: shop.id,
        color: shop.color,
        full_logo: shop.logo.svg_o_active_logo_path,
        short_logo: shop.logo.svg_o_logo_path,
      };

      const placemark = new ya_map_state.ymaps.Placemark(
        [shop.coordinate.latitude, shop.coordinate.longitude],
        {},
        getPlacemarkOptions(GeoObjectState.normal, placemarkOptions, [
          PLACEMARK_OFFSET,
          SIZE_FULL_PLACEMARK,
          SIZE_SHORT_PLACEMARK,
        ])
      );
      ya_map_state.shopsCollection.add(placemark);
      shop.all_zones.forEach((zone: IDeliveryZoneWithCoordinates) => {
        const coordinate = zone.coordinates.map(a => [a.latitude, a.longitude]);
        const polygon = new ya_map_state.ymaps.Polygon([coordinate], {}, ZONE_OPTIONS);
        ya_map_state.zonesCollection.add(polygon);
      });
    });

    if (ya_map_state.ymapsInstance) {
      ya_map_state.ymapsInstance.geoObjects.add(ya_map_state.shopsCollection as any);
      ya_map_state.ymapsInstance.geoObjects.add(ya_map_state.zonesCollection as any);
    }
  }, []);

  const fetchShopsZone = useCallback(async (id: number) => {
    if (id !== FAKE_CITY_ID) {
      const shops = await http.getShopsWithZones(id).then((res: IShopsWithZonesResponse) => res.list);
      return shops;
    }
    return [];
  }, []);

  const yMapSuccess = useCallback(
    async (api: any) => {
      const instance = new api.Map(
        COVERAGE_Y_MAP_ID,
        {
          center: [city.coordinate.latitude, city.coordinate.longitude],
          zoom: ZoomLevels.LG_FAR,
          controls: [],
        },
        { yandexMapDisablePoiInteractivity: true, suppressMapOpenBlock: true }
      );

      if (city.id !== FAKE_CITY_ID) {
        const shopsList = await fetchShopsZone(city.id);
        const ya_map_state = {
          ymaps: api,
          ymapsInstance: instance,
          shopsCollection: new api.GeoObjectCollection(),
          zonesCollection: new api.GeoObjectCollection(),
          shopsForPlaceCollection: new api.GeoObjectCollection(),
          currentShopZoneCollection: new api.GeoObjectCollection(),
          currentAddressCollection: new api.GeoObjectCollection(),
          hopsForPlace: [],
          allShops: shopsList,
          currentShopId: -1,
          currentShopIndex: -1,
          currentShopPlacemarkOptions: {},
          mapState: MapState.ZonesWithShops,
          isLoading: false,
        };
        parseShops(ya_map_state);
        yaMapState.current = ya_map_state;
      } else {
        return Promise.reject('your soul is mine!');
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (isBrowser) {
      if (!window.Ymap) window.Ymap = {};
      (window as any)['_ymap-api-onload'] = yMapSuccess;
      loadMapScript();
    }
    setCityId(city.id);
    return () => {
      if (window.Ymap) window.Ymap = null;
      (window as any)['_ymap-api-onload'] = () => null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (city.id !== cityId && cityId !== null) {
      (async () => {
        setCityId(city.id);
        const shops = await fetchShopsZone(city.id);
        const newYaMapState = { ...yaMapState.current, allShops: shops };
        updateCenter(newYaMapState, [city.coordinate.latitude, city.coordinate.longitude], ZoomLevels.FAR);
        parseShops(newYaMapState);
        yaMapState.current = newYaMapState;
      })();
    }
    // eslint-disable-next-line
  }, [city.id]);
};
