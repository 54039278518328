import React from 'react';
import styled from 'styled-components';
import { Box } from '@igooods/ui-kit';
import { mediaSmDown } from 'theme/helpers/css';
import { useCoverageZonesMap, useMap } from 'utils/hooks/useMap';
import { statesSA } from 'Containers/SelectAddress/constants';
import { IUseDataApiState } from 'utils/hooks';
import { IShopsForPlaceResponse } from 'Modules/Shops/http';

export const Y_MAP_ID = 'ya_map';
export const COVERAGE_Y_MAP_ID = 'coverage_ya_map';
const Y_MAP_TEST_ID = 'Y_MAP_TEST_ID';
const COVERAGE_Y_MAP_TEST_ID = 'COVERAGE_Y_MAP_TEST_ID';

const MapContainer = styled(Box)`
  height: 100%;
  ${mediaSmDown`
    [class$=-copyrights-pane]{
      bottom: 10px !important;
    }
  `}
`;

interface IMapBuilderProps {
  isScale?: boolean;
  modePage: statesSA;
  shopsForPlace?: IUseDataApiState<IShopsForPlaceResponse>;
  toggleScale?: () => void;
  saveAddressChanges?: () => void;
}

export const MapBuilder: React.FC<IMapBuilderProps> = ({
  modePage,
  shopsForPlace,
  isScale,
  toggleScale,
  saveAddressChanges,
}) => {
  const { controls } = useMap({
    modePage,
    shopsForPlace,
    isScale,
    toggleScale,
    saveAddressChanges,
  });
  return (
    <>
      {controls}
      <MapContainer data-testid={Y_MAP_TEST_ID} id={Y_MAP_ID} />
    </>
  );
};

export const CoverageZonesMapBuilder: React.FC = () => {
  useCoverageZonesMap();
  return <MapContainer data-testid={COVERAGE_Y_MAP_TEST_ID} id={COVERAGE_Y_MAP_ID} />;
};
